<template>
  <Layout>
    <div class="main-construction-detail">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <!-- 相册+介绍 -->
        <div class="md-cd-1 c-card">
          <!-- 相册 -->
          <swiper :options="swiperOption" class="imgs" v-ani.fade-up>
            <template v-for="(item, index) in infoObj.original_img">
              <swiper-slide :key="index">
                <ImgRatio :src='item' :width="500" :height='375'></ImgRatio>
              </swiper-slide>
            </template>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- 介绍 -->
          <div class="title s26 color-2 font-medium" v-ani.fade-up>{{ infoObj.title }}</div>
          <div class="data s16" v-ani.fade-up>
            <div class="data-item">
              <div class="t1 font-medium">项目规模：</div>
              <div class="t2 color-6">{{ infoObj.project_scale }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">业主单位：</div>
              <div class="t2 color-6">{{ infoObj.ownerUnit || '暂无' }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">总承包单位：</div>
              <div class="t2 color-6">{{ infoObj.totalUnit || '暂无' }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">项目投资规模：</div>
              <div class="t2 color-6">￥{{ infoObj.authentication_money || 0 }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">施工范围：</div>
              <div class="t2 color-6">{{ infoObj.construction_scope }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">项目位置：</div>
              <div class="t2 color-6">{{ infoObj.project_location }}</div>
            </div>
            <div class="data-item">
              <div class="t1 font-medium">融资规模：</div>
              <div class="t2 color-6">￥{{ infoObj.join_money || 0 }}</div>
            </div>
          </div>
        </div>
        <!-- 菜单 -->
        <div class="md-cd-2 c-card" v-ani.fade-up>
          <div class="list s16 color-2 font-medium">
            <template v-for="item in menu">
              <div class="li" :key="item.id" :class="{ on: item.id == current }" @click="changeMenu(item)">
                {{ item.title }}
              </div>
            </template>
          </div>
        </div>
        <!-- 详情模块列表 -->
        <div class="md-cd-3 c-card">
          <!-- 项目简介 -->
          <div class="mod mod-1" id="a-1" v-ani.fade-up>
            <div class="c-title-2 s22">项目简介</div>
            <div class="editor content s16 color-4">
              <div v-html="infoObj.details"></div>
            </div>
          </div>
          <!-- 项目视频 -->
          <div class="mod mod-2" id="a-5" v-ani.fade-up>
            <div class="c-title-2 s22">项目视频</div>
            <swiper :options="swiperOption2" class="imgs" :key="videoKey">
              <template v-for="(item, index) in infoObj.video">
                <swiper-slide :key="index" class="slide" @click.native="flayVideo(item)">
                  <ImgRatio :src="item.img" :width="370" :height='209'></ImgRatio>
                  <img :src="require('@/assets/images/icon-video.png')" class="icon">
                </swiper-slide>
              </template>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!-- 项目图片 -->
          <div class="mod mod-2" id="a-6" v-ani.fade-up>
            <div class="c-title-2 s22">项目图片</div>
            <swiper :options="swiperOption2" class="imgs">
              <template v-for="(item, index) in infoObj.image">
                <swiper-slide :key="index" class="slide">
                  <ImgRatio :src='item.file_url' :width="370" :height='209'></ImgRatio>
                </swiper-slide>
              </template>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <BaseDialog :visible.sync='visible' :cancel='false' @confirm='visible = false'>
          <div class="dialog-auth">
            <img src="@/assets/images/icon-auth.png" class="icon">
            <div class="tit s26 color-2 font-medium">暂无权限查看</div>
          </div>
        </BaseDialog>
      </div>
    </div>
    <!-- require('@/assets/video/movie.mp4') -->
    <BaseVideoDialog v-if='dialog_video' :video="currentVideo" @close="dialog_video = false" />
  </Layout>
</template>

<script>
import { SiteInfoApi } from "@/request/api/service";
export default {
  created() {
    SiteInfoApi({ engineer_id: this.$route.query.engineer_id }).then(({ status, data }) => {
      if (status == 200) {
        this.routes[2].name = data.title;
        if (data.video.length > 0) {
          data.video.map(item => {
            this.getVideoFirstFrame(item.file_url).then((dataUrl) => {
              item.img = dataUrl;
              this.videoKey++;
              // const resultDiv = document.getElementById('result');
              // resultDiv.innerHTML = `<img src="${dataUrl}" />`;
            })
              .catch((error) => { });
          })
        }
        this.infoObj = data;
      }
    })

  },
  data() {
    return {
      infoObj: {},
      routes: [
        { name: '首页', link: '/' },
        { name: '智慧工地', link: '/construction' },
        { name: '广州天力项目AB栋室内装修工程' }
      ],
      // 相册
      imgs: [
        { id: 1, img: require('@/assets/images/const-6.jpg') },
        { id: 2, img: require('@/assets/images/const-7.jpg') },
        { id: 3, img: require('@/assets/images/const-8.jpg') },
        { id: 4, img: require('@/assets/images/const-1.jpg') },
        { id: 5, img: require('@/assets/images/const-2.jpg') },
      ],
      swiperOption: {
        autoplay: 5000,
        autoplayDisableOnInteraction: false,
        slidesPerView: 3,
        spaceBetween: 20,
        // 响应显示隐藏
        observer: true,
        observeParents: true,
        // 分页
        pagination: '.swiper-pagination',
        paginationClickable: true
      },
      menu: [
        { id: 1, title: '项目简介', hasData: true },
        { id: 2, title: '施工组织', hasData: false },
        { id: 3, title: '材料需求', hasData: false },
        { id: 4, title: '劳务班组', hasData: false },
        { id: 5, title: '项目视频', hasData: true },
        { id: 6, title: '项目图片', hasData: true },
        { id: 7, title: '在线工程', hasData: false },
        { id: 8, title: '工程资料', hasData: false },
      ],
      current: 1,
      swiperOption2: {
        autoplay: 5000,
        autoplayDisableOnInteraction: false,
        slidesPerView: 4,
        spaceBetween: 20,
        // 响应显示隐藏
        observer: true,
        observeParents: true,
        // 分页
        pagination: '.swiper-pagination',
        paginationClickable: true
      },
      visible: false,
      dialog_video: false,
      currentVideo: "",
      videoKey: 0
    };
  },
  methods: {
    flayVideo(item) {
      this.currentVideo = item.file_url;
      this.dialog_video = true;
    },
    changeMenu(item) {
      if (item.hasData) {
        this.current = item.id
        let _t = document.querySelector('#a-' + item.id).offsetTop
        window.scroll({
          top: _t - 120,
          behavior: "smooth",
        })
      } else {
        this.visible = true
      }
    },

    getVideoFirstFrame(videoUrl) {
      // 创建video元素
      const video = document.createElement('video');
      video.src = videoUrl;
      video.setAttribute('crossOrigin', 'Anonymous'); // 处理跨域
      video.setAttribute('preload', 'auto'); // auto|metadata|none
      // 等待视频加载完成
      return new Promise((resolve, reject) => {
        video.addEventListener('loadedmetadata', () => {
          // debugger;
          // 创建canvas元素
          video.play();
          setTimeout(() => {
            video.pause();
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // 将视频第一帧绘制到canvas上
            const ctx = canvas.getContext('2d');

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // debugger;
            // 将canvas图像转换为base64格式的数据URI
            const dataUrl = canvas.toDataURL("image/png");

            // 返回base64格式的数据URI
            resolve(dataUrl);
          }, 500)

        });

        // 如果视频加载出错，则返回错误信息
        video.addEventListener('error', () => {
          reject(`Failed to load video: ${videoUrl}`);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-construction-detail {
  padding-bottom: .4rem;
}

.md-cd-1 {
  padding: .6rem .3rem .54rem;

  .imgs {
    padding-bottom: .4rem;

    .c-pic {
      border-radius: .04rem;
    }

    ::v-deep .swiper-pagination {
      bottom: 0;
      font-size: 0;

      &-bullet {
        background: #999;
        opacity: 1;
        width: .1rem;
        height: .1rem;
        min-width: 10px;
        min-height: 10px;
        margin: 0 .06rem;

        &-active {
          background: $blue;
        }
      }
    }
  }

  .title {
    margin-top: .24rem;
  }

  .data {
    margin-top: .13rem;
    line-height: calc(30 / 16 * 1em);

    &-item {
      display: flex;
      margin-bottom: .045rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .t2 {
      flex: 1;
    }
  }
}

.md-cd-2 {
  margin-top: 0.4rem;
  overflow: hidden;

  .list {
    display: flex;
    line-height: .7rem;
    text-align: center;

    .li {
      flex: 1;
      cursor: pointer;

      &:hover {
        color: $blue;
      }

      &.on {
        background: $blue;
        color: #fff;
      }
    }
  }
}

.md-cd-3 {
  margin-top: .22rem;
  padding: .3rem .3rem .33rem;

  .mod {
    border-bottom: 1px solid #e6f2f8;

    &:last-of-type {
      border: none;
    }

    ::v-deep {
      .swiper-container {
        padding-bottom: .38rem;
      }

      .swiper-pagination {
        font-size: 0;
        bottom: 3px;

        &-bullet {
          width: 6px;
          height: 6px;
          opacity: 1;
          margin: 0 7px;
          background: #999;

          &-active {
            background: $green;
            transform: scale(1.67);
          }
        }
      }
    }
  }

  .mod-1 {
    padding-top: 0.06rem;
    padding-bottom: 0.22rem;

    .content {
      line-height: calc(30 / 16 * 1em);
      margin-top: .17rem;
    }
  }

  .mod-2 {
    padding-top: .26rem;
    padding-bottom: 0.29rem;

    .imgs {
      margin-top: .24rem;

      .slide {
        position: relative;
        border-radius: .04rem;
        overflow: hidden;
      }

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: .6rem;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.dialog-auth {
  text-align: center;
  padding-top: .38rem;
  padding-bottom: 0.12rem;

  .icon {
    width: .4rem;
  }

  .tit {
    margin-top: .12rem;
  }
}
</style>